import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import SchedulingIcon from '../../images/svg/icons/feature-rota-scheduling.svg';
import TimeAttendanceIcon from '../../images/svg/icons/feature-time-attendance.svg';
import HrIcon from '../../images/svg/icons/feature-hr.svg';
import PayrollIcon from '../../images/svg/icons/feature-payroll.svg';
import ForecastingIcon from '../../images/svg/icons/feature-demand-forecasting.svg';
import IntegrationsIcon from '../../images/svg/icons/feature-integrations.svg';

import { ViewportBreakpoints } from '../cssConstants';
import ContentWrapper from '../layout/ContentWrapper';
import ResponsiveContainer from '../layout/ResponsiveContainer';
import TextHeadingWrapper from '../layout/TextHeadingWrapper';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import TextLink from '@rotaready/frecl/build/TextLink';

const Container = styled(FlexContainer).attrs({
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: props => !!props.rows,
})`
  > div {
    flex: 1 100%;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      flex: 1 calc(${({ rows }) => rows === 2 ? 33 : 16}% - 40px);
    }
  }
`;

const ItemContainer = styled.div`
  margin: 20px;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 0 4px 1px ${({ theme: { colors } }) => colors.boxshadowmedium};
    border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  }
`;

const IconContainer = styled.div`
  margin: 0 auto 20px auto;
  width: 40px;
  height: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 50px;
    height: 50px;
  }
`;

const TextContainer = styled.div`
  padding-top: 20px;
`;

const LinkContainer = styled.div`
  padding-top: 20px;
  margin-top: auto;
`;

export const Features = Object.freeze({
  ROTA_SCHEDULING: 1,
  TIME_ATTENDANCE: 2,
  HR: 3,
  PAYROLL: 4,
  DEMAND_FORECASTING: 5,
  INTEGRATIONS: 6,
});

class FeatureListItem extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subCopy: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    displayCopy: PropTypes.bool.isRequired,
  };

  handleOnClick = () => {
    const {
      url,
    } = this.props;

    navigate(url);
  };

  render() {
    const {
      title,
      subCopy,
      displayCopy,
      Icon,
    } = this.props;

    return (
      <ItemContainer onClick={this.handleOnClick}>
        <IconContainer>
          <Icon />
        </IconContainer>
        <H4 text={title} uistyle="brand160" />
        {displayCopy
        && (
          <TextContainer>
            <Text text={subCopy} uistyle="grey" />
          </TextContainer>
        )}
        <LinkContainer>
          <TextLink
            text="Explore feature"
            weight="bold"
            render={props => <span {...props} />}
          />
        </LinkContainer>
      </ItemContainer>
    );
  }
}

const FeatureListBreaker = ({
  displayCopy,
  excludeFeature,
  headingText,
  rows,
}) => (
  <ResponsiveContainer>
    <ContentWrapper>
      <TextHeadingWrapper>
        <H2 text={headingText} uistyle="brand160" />
      </TextHeadingWrapper>

      <Container rows={rows}>
        {excludeFeature !== Features.ROTA_SCHEDULING
        && (
          <FeatureListItem
            url="/rota-scheduling"
            title="Rota scheduling"
            subCopy="Rota software that's so intelligent it can build your staff rota for you. And our beautiful drag-and-drop rota editor is packed full of tools to help you optimise your shifts and control wage spend."
            displayCopy={displayCopy}
            Icon={SchedulingIcon}
          />
        )}

        {excludeFeature !== Features.TIME_ATTENDANCE
        && (
          <FeatureListItem
            url="/time-attendance"
            title="Time & attendance"
            subCopy="Clocking in and out has never been so simple. Capture employee attendance with the Rotaready app or an in-store tablet, manage overtime and approve timesheets for payroll."
            displayCopy={displayCopy}
            Icon={TimeAttendanceIcon}
          />
        )}

        {excludeFeature !== Features.HR
        && (
          <FeatureListItem
            url="/hr"
            title="HR"
            subCopy="Employee scheduling goes hand in hand with staffing, so we built everything you need to manage your employees, from time off and holiday accrual through to document storage and pay records."
            displayCopy={displayCopy}
            Icon={HrIcon}
          />
        )}

        {excludeFeature !== Features.PAYROLL
        && (
          <FeatureListItem
            url="/payroll"
            title="Payroll"
            subCopy="Keep your payroll in-house, integrate Rotaready with your existing payroll partner or opt for our fully managed service. You choose what works best for your business. "
            displayCopy={displayCopy}
            Icon={PayrollIcon}
          />
        )}

        {excludeFeature !== Features.DEMAND_FORECASTING
        && (
          <FeatureListItem
            url="/demand-forecasting"
            title="Demand forecasting"
            subCopy="Using past data to automatically predict sales, demand and other key factors, we help you build schedules with the perfect number of staff to meet demand. "
            displayCopy={displayCopy}
            Icon={ForecastingIcon}
          />
        )}

        {excludeFeature !== Features.INTEGRATIONS
        && (
          <FeatureListItem
            url="/integrations"
            title="Integrations"
            subCopy="We integrate with a whole host of key tools, from EPOS to reservations and payroll systems; meaning you can handpick the best solutions for every area of your business."
            displayCopy={displayCopy}
            Icon={IntegrationsIcon}
          />
        )}
      </Container>
    </ContentWrapper>
  </ResponsiveContainer>
);

FeatureListBreaker.propTypes = {
  displayCopy: PropTypes.bool,
  excludeFeature: PropTypes.oneOf(Object.values(Features)),
  rows: PropTypes.oneOf([1, 2]),
  headingText: PropTypes.string,
};

FeatureListBreaker.defaultProps = {
  displayCopy: false,
  excludeFeature: undefined,
  rows: 1,
  headingText: 'Rotaready features',
};

export default FeatureListBreaker;
